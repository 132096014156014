//
import { API_DOMAIN, AUTH_API_DOMAIN } from "constants/api";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import {
  Backdrop,
  Card,
  Content,
  CopyText,
  Floaty,
  Form,
  Headline,
  HorizontalRule,
  Input,
  Reader,
  Section,
  Select,
  Standard,
  Subtitle,
} from "@spring/smeargle";

import envUtils from "utils/environment";
import { LandingPageWrapper } from "components";
import routes from "routes";

const getEnv = () => {
  const rotom = encodeURIComponent(API_DOMAIN);
  const pikachu = encodeURIComponent(AUTH_API_DOMAIN);

  return {
    rotom,
    pikachu,
  };
};

const setEnv = (rotom, pikachu) => {
  const badURLEndings = /[/\s]+$/; // Trailing slash and/or whitespace

  localStorage.setItem("rotom", rotom.replace(badURLEndings, ""));
  localStorage.setItem("pikachu", pikachu.replace(badURLEndings, ""));

  window.location = window.location.href.split("?")[0];
};

const resetEnv = () => {
  localStorage.removeItem("rotom");
  localStorage.removeItem("pikachu");

  window.location.reload(false);
};

const Index = () => {
  const router = useRouter();

  const e2eRotom = "https://app-35939.on-aptible.com";
  const e2ePikachu = "https://app-35938.on-aptible.com";
  const localhostRotom = "http://localhost:4400";
  const localhostDiglet = "http://localhost:8081";
  const preprodRotom = "https://care-api.pre-prod.springtest.us";
  const preprodDiglet = "https://auth-api.pre-prod.springtest.us";
  const demoprodRotom = "https://care-api.demo.springhealth.com";
  const demoprodDiglet = "https://auth-api.demo.springhealth.com";
  const perfLabRotom = "https://app-40260.on-aptible.com";
  const perfLabDiglet = "https://app-40265.on-aptible.com";

  const devRotom = "https://care-api.dev.springtest.us";
  const devDiglet = "https://auth-api.dev.springtest.us";

  const codespacesRotom = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-4400.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : "";
  const codespacesDiglet = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-8081.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : "";

  const userAgentIsSafari =
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome");
  const currentEnv = getEnv();

  useEffect(() => {
    if (process.env.APP_ENV === "production") {
      const subdomain = window.location.host.split(".")[0] || "";
      const search = window.location.search;

      if (typeof window !== "undefined") {
        return window.open(
          `https://benefits.springhealth.com/${
            subdomain === "www" ? "" : subdomain
          }/${search ? `${search}` : ""}`,
          "_self",
        );
      }
    }

    if (envUtils.redirectCherrimHomepageToSignIn) {
      router.replace(routes.SignIn.to, routes.SignIn.as);
    } else if (router.query && router.query.rotom && router.query.pikachu) {
      setEnv(
        decodeURIComponent(router.query.rotom),
        decodeURIComponent(router.query.pikachu),
      );
    }
  }, [process.env.APP_ENV]);

  const setCurrentEnvironment = (env) => {
    const environments = {
      2: [e2eRotom, e2ePikachu],
      3: [localhostRotom, localhostDiglet],
      4: [preprodRotom, preprodDiglet],
      5: [demoprodRotom, demoprodDiglet],
      6: [perfLabRotom, perfLabDiglet],
      7: [codespacesRotom, codespacesDiglet],
      8: [devRotom, devDiglet],
    };

    let args = environments[env];
    const targetEnv = +env > 1 ? env : "";
    args ??= [
      `https://care-api${targetEnv}.staging.springtest.us`,
      `https://auth-api${targetEnv}.staging.springtest.us`,
    ];

    setEnv(...args);
  };

  const initialData = {
    rotom: API_DOMAIN,
    pikachu: AUTH_API_DOMAIN,
  };

  const options = [
    { label: "Staging", value: "1" },
    { label: "End to end", value: "2" },
    {
      label: "Localhost",
      value: "3",
      disabled: userAgentIsSafari,
    },
    { label: "Pre-production", value: "4" },
    {
      label: "Demo Production (Caution: can affect live sales demos!)",
      value: "5",
    },
    { label: "Performance Lab", value: "6" },
    { label: "Codespaces", value: "7" },
    { label: "AWS Development", value: "8" },
  ];

  const manualSwapFormActions = [
    {
      color: "error",
      icon: "trash-can",
      text: "Reset",
      onClick: () => {
        resetEnv();
      },
    },
  ];

  return (
    <>
      {!envUtils.magicEnvToggleEnabled && null}
      {envUtils.magicEnvToggleEnabled && (
        <LandingPageWrapper>
          <Standard>
            <Reader>
              <Headline noMargin>Magic ENV Toggle</Headline>
              <Subtitle noMargin status="warn">
                This page will only show up on non-production environments
              </Subtitle>

              <Section>
                <HorizontalRule />
              </Section>

              <Content>
                These forms can help you target any api. By default it will use{" "}
                {process.env.APP_API}.
              </Content>

              <Section>
                <Content bold>
                  Working on the backend? Use "Localhost" in the Quick Swap
                  drop-down to use the default ports or manually adjust as
                  needed. Unfortunately,
                  <a href="https://developer.mozilla.org/en-US/docs/Web/Security/Mixed_content#loading_locally_delivered_mixed-resources">
                    Safari does not support locally-delivered mixed-content
                  </a>
                  , so this won't work.
                </Content>
              </Section>
              <Section size="lg">
                <Floaty radius={3} float={1}>
                  <Card white>
                    <Subtitle noMargin>Quick Swap</Subtitle>
                    <Form
                      formKey="auto-env-change"
                      initialData={initialData}
                      onSubmit={({ env }) => {
                        setCurrentEnvironment(env);
                      }}
                    >
                      <Select
                        label="Select an ENV"
                        fieldKey="env"
                        options={options}
                      />
                    </Form>
                  </Card>
                </Floaty>
              </Section>

              <Section>
                <Floaty radius={3} float={1}>
                  <Card white>
                    <Subtitle noMargin>Manual Swap</Subtitle>
                    <Form
                      formKey="manual-env-change"
                      initialData={initialData}
                      onSubmit={({ rotom, pikachu }) => {
                        setEnv(rotom, pikachu);
                      }}
                      actions={manualSwapFormActions}
                    >
                      <Input fieldKey="rotom" label="rotom base url" />
                      <Input fieldKey="pikachu" label="pikachu base url" />
                    </Form>
                  </Card>
                </Floaty>
              </Section>

              <Section>
                <Floaty radius={3} float={1}>
                  <Card white>
                    <Subtitle>Environment Magnet Link</Subtitle>
                    <Content>
                      A shareable link that will automatically set the
                      environment you've set up above for anyone who clicks it.
                      Great for sharing in your PRs so that reviewers can get
                      set up easily to manually test your changes.
                    </Content>
                    <Backdrop color="primary">
                      <CopyText
                        link={`${window.location}?rotom=${currentEnv.rotom}&pikachu=${currentEnv.pikachu}`}
                        ariaLabel="Copy link"
                      />
                    </Backdrop>
                  </Card>
                </Floaty>
              </Section>
            </Reader>
          </Standard>
        </LandingPageWrapper>
      )}
    </>
  );
};

export default Index;
